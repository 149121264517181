import {createReducer} from 'redux-create-reducer';
import Immutable from 'immutable';

import {prepareHistoryDateBounds, toArray} from './functions';

import actionTypes from './actionTypes';

const tasks = {};

const initialState = Immutable.fromJS({
    status: 'initial',
    systemStatus: null,
    systemHistory: null,
    statusFilter: {},
    fetchStatus: null
});

const prepreIncList = incident => Immutable.fromJS(toArray(incident, true)
    .map(i => i && !isNaN(i) ? parseInt(i) : null)
    .filter(i => !!i));

tasks[actionTypes.INIT] = (state, action) => state.merge(Immutable.fromJS(initialState).withMutations(newState => {
    const location = action.payload;
    const {limit, start, finish, type, incident} = (location && location.query) || {};
    const {startDate, finishDate} = prepareHistoryDateBounds(
        start,
        finish
    );

    newState.setIn(['statusFilter', 'start'], startDate);
    newState.setIn(['statusFilter', 'finish'], finishDate);

    newState.setIn(['statusFilter', 'type'], type);

    if (limit >= 1) {
        newState.setIn(['statusFilter', 'limit'], parseInt(limit));
    }

    if (incident) {
        newState.setIn(['statusFilter', 'incident'], prepreIncList(incident));
    }
}));


tasks[actionTypes.FETCH_STATUS] = (state, action) => {
    return state.update('systemStatus', Immutable.Map(), systemStatus => {
        if (!systemStatus) {
            systemStatus = Immutable.Map();
        }
        return systemStatus.merge(Immutable.fromJS({
            error: null,
            status: action.payload && action.payload.status,
            code: action.payload && action.payload.status_code,
            lastUpdate: action.payload.uptime ? (Math.ceil(new Date().getTime() / 1000) - action.payload.uptime) * 1000 : 0,
            tag: action.payload.status_tag,
            incidents: action.payload && action.payload.incidents,
            incidentCount: action.payload && action.payload.incident_count,
            uptime: action.payload && action.payload.uptimes
        }));
    });
};

tasks[actionTypes.FETCH_STATUS_ERROR] = (state, action) => {
    return state.update('systemStatus', Immutable.Map(), systemStatus => {
        if (!systemStatus) {
            systemStatus = Immutable.Map();
        }
        return systemStatus.set('error', action.payload);
    });
};

tasks[actionTypes.FETCH_HISTORY] = (state, action) => {
    if (action.payload.data) {
        state = state.update('systemHistory', Immutable.Map(), systemHistory => {
            if (!systemHistory) {
                systemHistory = Immutable.Map();
            }
            return systemHistory.merge(Immutable.fromJS({
                error: null,
                log: action.payload && action.payload.data.incidents,
                paging: action.payload && action.payload.data.paging
            }));
        });
    }
    if (action.payload.filter) {
        state = state.update('statusFilter', Immutable.Map(), statusFilter => {
            if (!statusFilter) {
                statusFilter = Immutable.Map();
            }
            if (action.payload.filter.incident) {
                action.payload.filter.incident = prepreIncList(action.payload.filter.incident);
            }
            const newFilter = Immutable.fromJS(action.payload.filter).filter(v => v !== null);
            return statusFilter.merge(newFilter);
        });
    }

    return state;
};

tasks[actionTypes.FETCH_HISTORY_ERROR] = (state, action) => {
    if (action.payload.data) {
        state = state.update('systemHistory', Immutable.Map(), systemHistory => {
            if (!systemHistory) {
                systemHistory = Immutable.Map();
            }
            return systemHistory.set('error', action.payload.data);
        });
    }

    if (action.payload.filter) {
        state = state.update('statusFilter', Immutable.Map(), statusFilter => {
            if (!statusFilter) {
                statusFilter = Immutable.Map();
            }
            return statusFilter.merge(Immutable.fromJS(action.payload.filter));
        });
    }

    return state;
};

tasks[actionTypes.SET_HISTORY_TYPE] = (state, action) => {
    const type = action.payload;

    if (typeof type === 'string') {
        state = state.setIn(['statusFilter', 'type'], type);
    }

    return state;
};

tasks[actionTypes.SET_HISTORY_INCIDENT] = (state, action) => {
    const incident = action.payload;

    return state.setIn(['statusFilter', 'incident'], Immutable.List(incident));
};

tasks[actionTypes.PREFETCH] = (state, action) => {
    const {token, url, method} = action.payload || {};
    return state.update(
        'fetchStatus',
        Immutable.Map(),
        fetchStatus => {
            if (!fetchStatus) {
                fetchStatus = Immutable.Map();
            }
            return fetchStatus.set(token, Immutable.fromJS({
                url,
                method,
                status: 'prefetch'
            }));
        }
    );
};

tasks[actionTypes.POSTFETCH_SUCCESS] = (state, action) => {
    const {token} = action.payload || {};
    return state.hasIn(['fetchStatus', token]) ? state.setIn(['fetchStatus', token, 'status'], 'success') : state;
};

tasks[actionTypes.POSTFETCH_FAIL] = (state, action) => {
    const {token, error} = action.payload || {};
    return state.hasIn(['fetchStatus', token]) ?
        state.setIn(['fetchStatus', token, 'status'], 'fail')
            .setIn(['fetchStatus', token, 'error'], error)
        :
        state;
};

tasks[actionTypes.POSTFETCH] = (state, action) => {
    const {token} = action.payload || {};
    return state.hasIn(['fetchStatus', token]) ? state.setIn(['fetchStatus', token, 'done'], true) : state;
};

export default createReducer(Immutable.Map(), tasks);