import React from 'react';

import classNames from 'classnames';

import styles from './Button.module.scss';

export default class Button extends React.Component {
    static styles = styles;
    static defaultProps = {
        type: 'submit',
        title: undefined,
        ico: false,
        icoPosition: 'before',
        submitValue: true,
        ignorePristine: false,
        ignoreSubmitting: false,
        checkFormValid: false
    }

    render = () => {
        const btnProps = {...this.props};
        delete btnProps.to;
        delete btnProps.asField;
        delete btnProps.component;
        delete btnProps.props;
        delete btnProps.asArray;
        delete btnProps.noDefer;
        delete btnProps.noOverwriteName;
        delete btnProps.noRef;
        delete btnProps.validate;
        delete btnProps.label;
        delete btnProps.dragState;
        delete btnProps.bold;
        delete btnProps.flat;
        delete btnProps.meta;
        delete btnProps.ignorePristine;
        delete btnProps.ignoreSubmitting;
        delete btnProps.active;
        delete btnProps.submitValue;
        delete btnProps.resetValues;
        delete btnProps.handlingEvents;
        delete btnProps.onOptionDelete;
        delete btnProps.formIsPristine;
        delete btnProps.formIsValid;
        delete btnProps.formIsDisabled;
        delete btnProps.formIsSubmitting;
        delete btnProps.addValidationTitle;
        delete btnProps.checkFormValid;
        delete btnProps.disableWhileHandling;

        delete btnProps.ico;
        delete btnProps.icoPosition;

        btnProps.className = classNames(
            this.props.className,
            {
                [styles['button']]: !!styles['button'],
                [styles['bold']]: !!styles['bold'] && this.props.bold,
                [styles['flat']]: !!styles['flat'] && this.props.flat,
                [styles['selected']]: !!styles['selected'] && this.props.active,
                [styles['icon-only']]: !!styles['icon-only'] && this.props.icoPosition === 'only'
            }
        );

        btnProps.children = typeof this.props.children === 'function' ? this.props.children(this.props) : this.props.children;

        return <button {...btnProps}/>;
    }
}