import React from 'react';

import classNames from 'classnames';

import {toMoment, getPopupContainer} from '../functions';

import * as calendarUtils from 'rc-calendar/es/util';
import RCDatePicker from 'rc-calendar/es/Picker';

import Input from './Input';
import Calendar from './Calendar';

import styles from './Datepicker.module.scss';

class RCDatePickerExtended extends RCDatePicker {
    render() {
        let element = super.render();
        if (typeof this.props.onPopupAlign === 'function') {
            element = React.cloneElement(element, {onPopupAlign: this.props.onPopupAlign});
        }
        return element;
    }
}

export default class Datepicker extends React.Component {
    static defaultProps = {
        withTimepicker: false,
        adaptiveWidth: false,
        minDate: () => toMoment(0)
    };
    setPickerRef = node => {
        this.renderPicker = node;
    }

    render = () => {
        const {className, calendarClassName, disabledDate, disabledTime, input} = this.props;
        const inputProps = input ? {value: input.value, onChange: input.onChange} : {}
        const pickerProps = {
            ...this.props,
            ...inputProps
        };
        const calendarProps = {};

        const value = (this.props.input && this.props.input.value) || this.props.value;

        const valueObject = toMoment(value);

        calendarProps.full = true;
        calendarProps.format = 'MM/DD/YYYY';
        calendarProps.showDateInput = true;
        calendarProps.className = classNames(calendarClassName, styles['datepicker']);
        calendarProps.showToday = disabledDate && valueObject && valueObject.locale && calendarUtils.isAllowedDate(calendarUtils.getTodayTime(valueObject), disabledDate);
        calendarProps.showOk = calendarProps.showToday;
        calendarProps.disabledDate = disabledDate;
        calendarProps.disabledTime = disabledTime;
        calendarProps.timePicker = <span />;
        //calendarProps.prefixCls = 'datepicker';
        calendarProps.ref = 'renderCalendar';

        pickerProps.format = calendarProps.format;
        pickerProps.animation = 'slide-up';//animation;
        pickerProps.align = {
            points: ['tl', 'tl'],
            overflow: {
                adjustX: 0,
                adjustY: 1
            },
            offset: [0, 0],
            targetOffset: [0, 0]
        };
        
        pickerProps.value = toMoment(pickerProps.value);

        pickerProps.calendar = <Calendar {...calendarProps} />;
        pickerProps.prefixCls = 'datepicker-picker';

        pickerProps.dropdownClassName = classNames(className, styles['datepicker-picker']);
        pickerProps.getCalendarContainer = getPopupContainer(this);
        pickerProps.align = {useCssTransform: true};
        pickerProps.ref = this.setPickerRef;

        return <RCDatePickerExtended {...pickerProps}>{pickerState => {
            const value = pickerState.value ? toMoment(pickerState.value).format(calendarProps.format) : '';
            let minWidth = 0;
            if (this.props.adaptiveWidth) {
                minWidth = Math.max(value.length, pickerProps.format && pickerProps.format.length) + 2;
            }
            const inputProps = {
                className: classNames(className, styles['datepicker-inputhandle'], 'datepicker-inputhandle'),
                value,
                disabled: pickerProps.disabled,
                name: pickerProps.name,
                inheritName: false,
                asComponent: true,
                style: {minWidth: `${minWidth}ch`},
                asEditable: true
            };
            return <Input {...inputProps}/>;
        }}</RCDatePickerExtended>;
    }
}