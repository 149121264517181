import React from 'react';

import classNames from 'classnames';

import {ReactComponent as LoaderLogo} from '../svg/logo-anim.svg';

import styles from './Loader.module.scss';

export default class Loader extends React.Component {
    render = () => <div className={classNames(styles['spinner-container'], this.props.containerClassName)}>
        <div className={classNames(this.props.className, styles.spinner, styles.logo)}>
            <div className={styles['spinner-wrap']}>
                <div className={styles.image} key="logo"><LoaderLogo /></div>
                <div className={styles.text} key="text">loading <span className={styles.dot}>.</span><span className={styles.dot}>.</span><span className={styles.dot}>.</span></div>
            </div>
        </div>
    </div>;
}