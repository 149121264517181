import React from 'react';

import {toMoment} from '../functions';
//
//import Loader from './Loader'
import './Calendar.css'

import RCCalendar from 'rc-calendar';
import RCDateTable from 'rc-calendar/es/date/DateTable';

export default class Calendar extends React.Component {
    selectHandle = evt => {
        return this.props.onSelect &&  this.props.onSelect(evt);
    }

    focus = () => {
        return this.calendarInstance
            && typeof this.calendarInstance.focus === 'function'
            && typeof this.calendarInstance.focus();
    }

    ref = node => {
        this.calendarInstance = node;
    }

    render = () => {
        const Component = this.props.full ? RCCalendar : RCDateTable;

        const calendarProps = {
            showDateInput: !this.props.full,
            showToday: !this.props.full,
            showOk: false,
            prefixCls: 'calendar',
            timePicker: null,
            ...this.props,
            onSelect: this.selectHandle,
            ref: this.ref
        };

        if (this.props.value) {
            calendarProps.value = toMoment(this.props.value);
        }
        if (this.props.defaultValue) {
            calendarProps.defaultValue = toMoment(this.props.defaultValue);
        }

        delete calendarProps.full;
        
        return <Component {...calendarProps}/>;
    }
}