import React from 'react';
import {statusConnector} from '../connector';

import {toMoment} from '../functions';

import Loader from './Loader'
import Button from './Button'
import Dialog from './Dialog'

import classNames from 'classnames';

import Icon from './Icon';

import {ReactComponent as WarningIcon} from '../svg/icons/warning.svg';
import {ReactComponent as InfoCircleIcon} from '../svg/icons/info-circle.svg';
import {ReactComponent as ExclamationCircleIcon} from '../svg/icons/exclamation-circle.svg';
import {ReactComponent as CheckCircleIcon} from '../svg/icons/check-circle.svg';
import {ReactComponent as TwitterIcon} from '../svg/icons/twitter.svg';

import SubscribeForm from './SubscribeForm';

import styles from './Status.module.scss'

export class Status extends React.Component {
    static errorCodeTags = {
        queue_slow: 'warn',
        deploy: 'deploy',
        pc_queue_slow: 'warn',
        api_out: 'error',
        vidly_down: 'error',
        vidly_slow: 'warn',
        aspera_out: 'error'
    };

    componentWillMount = () => this.checkStatus()
    componentWillReceiveProps = props => this.checkStatus(props)
    componentWillUnmount = () => {
        if (this._timeouts) {
            Object.keys(this._timeouts).forEach(tm => {
                clearTimeout(this._timeouts[tm]);
            });
        }
    }

    refreshStatus = (props = this.props) => {
        if (this._timeouts && this._timeouts.check) {
            clearTimeout(this._timeouts.check);
        }
        if (!this._timeouts) {
            this._timeouts = {};
        }
        this._timeouts.check = setTimeout(
            () => {
                this.loadStatus(props, true);
                this.refreshStatus();
            },
            10000
        );
    }
    
    getHeaderParams = (props = this.props) => {
        const loading = this.state && this.state.loading;

        const warnLevel = this.getWarningLevel(props);

        let headerText;
        let headerIco;

        switch (warnLevel) {
            default:
                headerText = loading ? 'Updating...' : 'Encoding.com system status.';
                headerIco = InfoCircleIcon;
                break;
            case 'ok':
                headerText = 'All systems go.';
                headerIco = CheckCircleIcon;
                break;
            case 'warning':
                headerText = 'We are running a little slow.';
                headerIco = WarningIcon;
                break;
            case 'deploy':
                headerText = 'System deploy in progress.';
                headerIco = InfoCircleIcon;
                break;
            case 'error':
                headerText = 'We have a problem.';
                headerIco = ExclamationCircleIcon;
                break;
        }
        
        return {headerText, headerIco};
    }

    checkStatus = (props = this.props) => {
        const {systemStatus} = props;
        if (!systemStatus) {
            this.loadStatus(props);
        } else {
            let faviconsPath = '/favicons/';
            const replReg = new RegExp('/favicons/((deploy|warn|error)/)?');
            let warnLevel = this.getWarningLevel(props);

            if (warnLevel !== 'ok') {
                switch (warnLevel) {
                    case 'warning':
                    default:
                        faviconsPath += 'warn/';
                        break;
                    case 'error':
                    case 'deploy':
                        faviconsPath += `${warnLevel}/`;
                        break;
                }
            }
            if (document && document.querySelectorAll) {
                document.querySelectorAll('[rel~="icon"]').forEach(node => {
                    const newHref = node.href.replace(replReg, faviconsPath);
                    if (newHref !== node.href) {
                        node.href = newHref;
                    }
                });
                if (!document._originalTitle) {
                    document._originalTitle = document.title;
                }
                const {headerText} = this.getHeaderParams(props);
                document.title = `${document._originalTitle} :: ${headerText}`;
            }

        }
        this.refreshStatus();
    }

    loadStatus = (props = this.props, silent = false) => {
        const {fetchStatus} = props.actions || {};

        if (typeof fetchStatus === 'function') {
            this.setState({loading: !silent});
            fetchStatus().finally(() => {
                this.setState({loading: false});
            });
        }
    }

    subscribeToUpdates = (props = this.props, email = '') => {
        let ret;
        if (email) {
            const {subscribeToUpdates} = props.actions || {};

            if (typeof subscribeToUpdates === 'function') {
                this.setState({subscribing: true});
                ret = subscribeToUpdates(email).finally(() => {
                    this.setState({subscribing: false});
                });
            }
        }
        return ret;
    }

    refreshHandle = () => this.loadStatus();

    subscribeHandle = values => this.subscribeToUpdates(undefined, values && values.get('email'));

    getWarningLevel = (props = this.props) => {
        const {systemStatus} = props;
        let tag = '';
        if (systemStatus) {
            tag = systemStatus.get('tag');
            if (!tag && Status.errorCodeTags[systemStatus.get('code')]) {
                tag = Status.errorCodeTags[systemStatus.get('code')];
            }
        }

        return tag;
    }

    renderStatusDetails = () => {
        const loading = this.state && this.state.loading;
        const {systemStatus} = this.props;

        const {headerText, headerIco} = this.getHeaderParams();

        const rows = [];

        if (systemStatus) {

            if (systemStatus.get('incidents')) {
                const incDateFormat = 'HH:mm:ss';
                systemStatus.get('incidents').forEach(inc => {
                    rows.push(<div key={`incident-${inc.get('id')}`} className={classNames(styles.row, styles['row-incident'])}>
                        <dt>{inc.get('name')}</dt>
                        <dd>
                            <ul className={styles.updates}>
                                <li className={styles.description}>
                                    <span className={styles.date}>{toMoment(inc.get('published')).format(incDateFormat)}</span>
                                    &nbsp;&mdash;&nbsp;
                                    {inc.get('description')}
                                </li>
                                {inc.get('updates').count() ? inc.get('updates').map(upd => <li key={`upd-${upd.get('id')}`} className={styles.update}>
                                    <span className={styles.date}>{toMoment(upd.get('published')).format(incDateFormat)}</span>
                                    &nbsp;&mdash;&nbsp;
                                    {upd.get('description')}
                                </li>) : null}
                            </ul>
                        </dd>
                    </div>);
                });
            }

            if (systemStatus.get('lastUpdate')) {
                rows.push(<div key="lastUpdate" className={classNames(styles.row, styles['row-last-update'])}>
                    <dt>Last Incident:</dt>
                    <dd>{toMoment(systemStatus.get('lastUpdate'), true).format('MMMM D, YYYY HH:mm:ss')}</dd>
                </div>);
            }

            if (systemStatus.get('incidentCount')) {
                rows.push(<div key="incidentsCountMonth" className={classNames(styles.row, styles['row-incident-count'], styles['row-incident-count-month'])}>
                    <dt>Incidents this month:</dt>
                    <dd>{systemStatus.getIn(['incidentCount', 'lastMonth'])}</dd>
                </div>);
                rows.push(<div key="incidentsCountYear" className={classNames(styles.row, styles['row-incident-count'], styles['row-incident-count-year'])}>
                    <dt>Incidents this year:</dt>
                    <dd>{systemStatus.getIn(['incidentCount', 'lastYear'])}</dd>
                </div>);
            }
            if (systemStatus.get('uptime')) {
                rows.push(<div key="uptimeToday" className={classNames(styles.row, styles['row-uptime'], styles['row-uptime-today'])}>
                    <dt>Uptime today:</dt>
                    <dd>{systemStatus.getIn(['uptime', 'today'])}</dd>
                </div>);
                rows.push(<div key="uptimeWeek" className={classNames(styles.row, styles['row-uptime'], styles['row-uptime-week'])}>
                    <dt>Uptime this week:</dt>
                    <dd>{systemStatus.getIn(['uptime', 'week'])}</dd>
                </div>);
                rows.push(<div key="uptimeMonth" className={classNames(styles.row, styles['row-uptime'], styles['row-uptime-month'])}>
                    <dt>Uptime this month:</dt>
                    <dd>{systemStatus.getIn(['uptime', 'month'])}</dd>
                </div>);
            }
        }

        return <div className={styles['status-details']}>
            {loading || !this.props.systemStatus ? <Loader className={styles.loader} /> : null}
            <h2 key="header" className={styles.header}>
                {headerIco ? <Icon className={styles.icon} ico={headerIco} /> : null}
                {headerText}
            </h2>
            <dl>{rows}</dl>
        </div>;
    }

    showSubscribeForm = visible => this.setState({
        showSubscribeMethods: visible
    });
    
    subscribeButtonHandle = () => this.showSubscribeForm(true);

    renderSubscribe = () => {
        const subscribing = this.state && this.state.subscribing;
        const showSubscribeMethods = subscribing || (this.state && this.state.showSubscribeMethods);

        return <div className={styles.subscibe}>
            <h2 className={styles['subscibe-header']}>Stay in sync with platform updates</h2>
            <div className={styles['subscibe-methods']}>
                Select Your favourite method how you will receive notifications:
                <ol className={styles['list']}>
                    <li><span>Integrate the <a href="https://api.encoding.com/reference-link/status-api-current">Status API</a> into your application.</span></li>
                    <li>
                        <span>
                            <Button disabled={showSubscribeMethods} onClick={this.subscribeButtonHandle} className={styles['subscribe-button']} bold>
                                Subscribe&nbsp;to updates by&nbsp;email
                            </Button>
                        </span>
                    </li>
                    <li><span>Follow us on <a href="https://twitter.com/encodingdotcom"><Icon ico={TwitterIcon} className={styles['twitter-icon']} /> Twitter</a> to receive live updates.</span></li>
                </ol>
            </div>
            <Dialog visible={showSubscribeMethods} closable={!subscribing} className={styles['subscibe-dialog']} onVisibilityChange={this.showSubscribeForm}>
                <h3 className={styles['subscibe-header']}>Email subscription</h3>
                <p className={styles['subscribe-description']}>Provide us Your email and receive all system updates as fast as possible</p>
                <SubscribeForm styles={styles} className={styles['subscribe-form']} onSubmit={this.subscribeHandle} autoFocus/>
            </Dialog>
        </div>;
    }

    render = () => {
        const warnLevel = this.getWarningLevel();

        return  <section className={classNames(styles.status, styles[`status-${warnLevel}`])}>
            {this.renderStatusDetails()}
            {this.renderSubscribe()}
        </section>
    }
}

export default statusConnector(Status);
