import React from 'react';

import classNames from 'classnames';

import Button from './Button'
import Icon from './Icon';
import {ReactComponent as CloseIcon} from '../svg/icons/close.svg';

import styles from './Dialog.module.scss'

export default class Dialog extends React.Component {
    static defaultProps = {
        visible: false,
        defaultVisible: false,
        closable: true,
        closeOnEsc: true
    }
    componentWillMount = () => {
        this.setVisible(this.props.defaultVisible || this.props.visible);
    }
    componentWillReceiveProps = props => {
        const prevVisible = this.state && this.state.visible;
        if (this.props.visible !== props.visible && prevVisible !== props.visible) {
            this.setVisible(props.visible, props);
        }
    }
    keyDownHandle = event => {
        if(event && event.keyCode === 27) {
            this.setVisible(false);
        }
    }
    setVisible = (visible, props = this.props) => {
        const prevVisible = this.state && this.state.visible;
        if (prevVisible !== visible) {
            this.setState({visible});
            if (typeof this.props.onVisibilityChange === 'function') {
                this.props.onVisibilityChange(visible);
            }
            if (props.closable && props.closeOnEsc) {
                if (visible) {
                    document && document.addEventListener("keydown", this.keyDownHandle, false);
                } else {
                    document && document.removeEventListener("keydown", this.keyDownHandle, false);
                }
            }
        }
    }
    closeHandle = () => {
        this.setVisible(false);
    }
    render = () => {
        const visible = this.state && this.state.visible;

        return visible ? [
            <div key="dialog" className={classNames(styles.dialog, this.props.className)}>
                <ul className={styles.controls}>
                    {this.props.closable ? <li><Button icoPosition="only" onClick={this.closeHandle}><Icon className={Button.styles.ico} ico={CloseIcon}/></Button></li> : null}
                </ul>
                <div className={styles.wrap}>
                    {this.props.children}
                </div>
            </div>,
            <div key="tint" className={classNames(styles['dialog-tint'], this.props.tintClassName)} onClick={this.closeHandle}/>
        ] : null;
    }
}