import moment from 'moment';
import url from 'url';
import qs from 'qs';

export const parseURLString = (urlToParse) => {
    let urlScheme;
    if (typeof urlToParse === 'string') {
        const urlAuthRegExp = /\/\/([^:]*)(:.*)?@/;
        if (urlToParse) {
            if (urlToParse.indexOf('://') < 0) {
                urlToParse = '//' + urlToParse;
            } else {
                urlToParse = urlToParse.replace(/(\w+:\/\/)*(\w+:\/\/)/ig, '$2');
            }
            if (urlAuthRegExp.test(urlToParse)) {
                urlToParse = urlToParse.replace(
                    urlAuthRegExp,
                    (match, login, pass) => {
                        let parsedLogin = login || '';
                        let parsedPass = pass ? pass.substr(1) : '';
                        try {
                            parsedLogin = decodeURIComponent(parsedLogin);
                        } catch (e) {
                            //do nothing
                        }
                        try {
                            parsedPass = decodeURIComponent(parsedPass);
                        } catch (e) {
                            //do nothing
                        }
                        parsedLogin = encodeURIComponent(parsedLogin);
                        parsedPass = encodeURIComponent(parsedPass);

                        return  `//${parsedLogin}${pass ? ':' + parsedPass : ''}@`;
                    }
                );
            }
        }
        try {
            urlScheme = url.parse(
                urlToParse,
                false,
                true
            );
        } catch (e) {
            urlScheme = false;
        }
        if (urlScheme) {
            urlScheme.query = urlScheme.search ? qs.parse(urlScheme.search.replace(/^\?/, '')) : {};

            urlScheme.hostname = urlScheme.hostname || '';
            urlScheme.pathname = urlScheme.pathname || '';
            try {
                urlScheme.pathname = decodeURIComponent(urlScheme.pathname);
            } catch (e) {
            }
        }
    }

    return urlScheme;
};

export const queryStringify = params => qs.stringify(params, {encodeValuesOnly: true}).replace(/=($|&)/gi, '$1');

export const updateLocation = (newLocation = '', prevLocation = '', force = false, push = true) => {
    let ret;
    if (!prevLocation) {
        prevLocation = window && window.location && window.location.href;
    }
    if (newLocation && (newLocation !== prevLocation || force)) {
        const histHandler = `${push ? 'push' : 'replace'}State`;
        window && window.history && window.history[histHandler](
            {newLocation, prevLocation, force},
            document && document.title,
            newLocation
        );
    }

    return ret;
};

export const isMoment = date => moment.isMoment(date);

const tzOffset = moment().utcOffset();

export const extendMomentClone = (date) => {
    if (isMoment(date)) {
        if (!date._origClone) {
            date._origClone = date.clone;
            date.clone = function () {
                const newDate = date._origClone.call(this);
                newDate._tzupdated = this._tzupdated;
                extendMomentClone(newDate);
                return newDate;
            }
        }
    }
}

export const toMoment = (date, localTimeZone) => {
    let momentDate;
    if (typeof localTimeZone === 'undefined') {
        localTimeZone = !date || date instanceof Date;
    }
    if (date) {
        if (typeof date === 'object') {
            momentDate = isMoment(date) ? date.clone() : moment(date);
        } else {
            momentDate = moment(
                date,
                [
                    'x',
                    'YYYY-MM-DD HH:mm:ss',
                    'YYYY-M-DD HH:mm:ss',
                    'YYYY-MM-DD',
                    'YYYY-M-DD',
                    'HH:mm:ss'
                ]
            );
        }
    } else {
        momentDate = moment();
    }
    if (!momentDate._tzupdated) {
        momentDate._tzupdated = localTimeZone;

        if (!localTimeZone) {
            momentDate._tzupdated = true;
            momentDate.add(tzOffset, 'minutes');
        }
    }

    extendMomentClone(momentDate);

    return momentDate;
};

export const toNeutralTZ = date => toMoment(date)
    .hours(12)
    .minutes(0)
    .seconds(0)
    .millisecond(0);

export const toArray = (subj, splitChar = false) => {
    let ret;
    if (subj instanceof Array) {
        ret = subj;
    } else if (splitChar) {
        ret = (subj + '').split(splitChar === true ? ',' : splitChar);
    } else {
        ret = [subj];
    }
    return ret;
}

export const getHistoryDateBounds = field => {
    const bounds = {
        min: [1, 'years'],
        defMin: [6, 'months'],
        max: [1, 'days']
    };

    return field ? bounds[field] : bounds;
};

export const prepareHistoryDateBounds = (start, finish, boundLimit = false) => prepareDateBounds(
    start,
    finish,
    getHistoryDateBounds(boundLimit ? 'min' : 'defMin'),
    getHistoryDateBounds('max')
);

export const prepareDateBounds = (start, finish, startDiff, finishDiff) => {
    let finishDate = toMoment(finish);
    let startDate;
    const now = toMoment();

    const startDiffOpt = toArray(startDiff);
    const finishDiffOpt = toArray(finishDiff);


    if (!startDiffOpt[1]) {
        startDiffOpt[1] = 'days';
    }
    if (!finishDiffOpt[1]) {
        finishDiffOpt[1] = 'days';
    }

    if (now.diff(finishDate, 'days') < 0) {
        finishDate = now;
    }
    if (start) {
        startDate = toMoment(start);
    } else {
        startDate = finishDate.clone().subtract(startDiffOpt[0], startDiffOpt[1]);
    }
    if (finishDate.diff(startDate, finishDiffOpt[1]) < finishDiffOpt[0]) {
        startDate = finishDate.clone().subtract(finishDiffOpt[0], finishDiffOpt[1]);
    }

    return {
        startDate: toNeutralTZ(startDate),
        finishDate: toNeutralTZ(finishDate)
    };
};

export const checkElement = (id, parent = document.body) => {
    let ret = document.getElementById(id);
    if (!ret || ret.parentNode !== parent) {
        if (!ret) {
            ret = document.createElement('div');
        }
        ret.id = id;
        parent.appendChild(ret);
    }
    return ret;
};
export const getPopupContainer = (instance, sub = false) => (/*node*/) => {
    const rootID = 'popup-root';
    let root = checkElement(rootID);
    if (typeof sub === 'string') {
        const subID = `${rootID}-${sub}`.replace(/[^a-z-]/, '-');
        root = checkElement(subID, root);
    }
    return root;
};