/* global Promise, fetch */

import actionTypes from './actionTypes';
import mediaType from 'medium-type';
import url from 'url';
import {toMoment, toNeutralTZ, prepareHistoryDateBounds} from './functions';

export const init = location => ({type: actionTypes.INIT, payload: location});

const uniqueToken = () => (new Date().getTime().toString(36) + Math.random().toString(36)).replace('.', '');

const statusUrl = (options = {}) => {
    const query = {
        format: 'json',
        ...(options.query || {})
    };
    
    const pathname = `./api-proxy/${options.pathname || ''}`;
    
    const urlParams = {
        ...options,
        pathname,
        query
    };
    
    if (process.env.NODE_ENV === 'development') {
        urlParams.protocol = 'https:';
        urlParams.host = 'status3.encoding.com';
    }
    
    return url.format(urlParams);
}

export const setHistoryIncident = incident => ({type: actionTypes.SET_HISTORY_INCIDENT, payload: incident});

export const setHistoryType = type => ({type: actionTypes.SET_HISTORY_TYPE, payload: type});

export const fetchStatus = () =>
    (dispatch, getState) => {
        return Promise.all([
            dispatch(fetchAPI(statusUrl({pathname: 'status.php'})))/*,
            dispatch(fetchAPI(statusUrl({pathname: 'archive.php', query: {limit: 1, from: 0}})))*/
        ]).then(([resp/*, histResp*/]) => {
            let type, payload;
            if (!resp.error) {
                type = actionTypes.FETCH_STATUS;
                payload = resp.response;
                const prevStatus = getState().getIn(['app', 'systemStatus', 'status']);
                if (prevStatus && payload.status && prevStatus !== payload.status) {
                    dispatch(fetchHistory());
                }
            } else {
                type = actionTypes.FETCH_STATUS_ERROR;
                payload = resp.error;
            }

            /*if (histResp.response) {
                payload.lastIncidents = histResp.response.incidents &&  histResp.response.incidents[0];
            }*/

            return dispatch({
                type,
                payload
            });
        });
    };

export const fetchHistory = (params = {}, ignoreFilter = false) =>
    (dispatch, getState) => {
        const filterParams = {
            start: null,
            finish: null,
            type: null,
            from: null,
            limit: 1000,
            ...(params || {})
        }
        if (!ignoreFilter) {
            const statusFilter = getState().getIn(['app', 'statusFilter']);
            if (statusFilter) {
                if (!filterParams.start) {
                    filterParams.start = statusFilter.get('start');
                }
                if (!filterParams.finish) {
                    filterParams.finish = statusFilter.get('finish');
                }
                if (!filterParams.from && filterParams.from !== 0) {
                    filterParams.from = statusFilter.get('from');
                }
                if (!filterParams.limit && filterParams.limit !== 0) {
                    filterParams.limit = statusFilter.get('limit');
                }
            }
        }

        const query = {};

        const filterBounds = prepareHistoryDateBounds(filterParams.start, filterParams.finish);

        filterParams.start = filterBounds.startDate;
        filterParams.finish = filterBounds.finishDate;

        if (filterParams.start) {
            filterParams.start = toNeutralTZ(toMoment(filterParams.start));
            if (filterParams.start) {
                query.from_date = filterParams.start.format('YYYY-MM-DD');
            }
        }

        if (filterParams.finish) {
            filterParams.finish = toNeutralTZ(toMoment(filterParams.finish));
            if (filterParams.finish) {
                query.to_date = filterParams.finish.format('YYYY-MM-DD 23:59:59');
            }
        }

        if (filterParams.from !== null && filterParams.from >= 0) {
            query.from = filterParams.from;
        }
        if (filterParams.limit !== null && filterParams.limit >= 1) {
            query.limit = filterParams.limit < 1000 ? filterParams.limit : 1000;
        }
        if (filterParams.type !== null) {
            query.tag = filterParams.type;
        }

        return dispatch(fetchAPI(statusUrl({pathname: 'archive.php', query}), undefined, null, false))
            .then(resp => {
                let type, data;
                if (!resp.error) {
                    type = actionTypes.FETCH_HISTORY;
                    data = resp.response;
                } else {
                    type = actionTypes.FETCH_HISTORY_ERROR;
                    data = resp.error;
                }

                return dispatch({
                    type,
                    payload: {
                        data,
                        filter: filterParams
                    }
                });
            });
    };

export const subscribeToUpdates = (email) =>
    (dispatch) => {
        const data = url.format({query: {action: 'subscribe', email}}).replace(/^\?/, '');
        return dispatch(fetchAPI(
            statusUrl({pathname: 'status.php'}),
            'POST',
            data,
            true,
            {
                'Content-Type': 'application/x-www-form-urlencoded'/*,
                'X-Requested-With': 'XMLHttpRequest'*/
            }
            ))
            .then(resp => {
                if (!resp || !resp.response) {
                    throw new Error('Something went wrong');
                }
                if (resp && resp.response && resp.response.status === 'error') {
                    throw new Error(resp.response.message);
                }
                return resp;
            });
    };

export const fetchAPI = (url, method = 'GET', body = null, throwOnError = true, headers = {}) => dispatch => {
    const token = uniqueToken();
    return Promise.resolve()
        .then(() => dispatch({
            type: actionTypes.PREFETCH,
            payload: {
                url,
                method,
                token
            }
        }))
        .then(() => {
            const reqOptions = {
                headers: {
                    Accept: 'application/json',
                    ...headers
                },
                method,
                redirect: 'error',
                cache: 'no-store'
            };
            if (body) {
                reqOptions.body = body;
            }
            return fetch(url, reqOptions)
                .then(resp => {
                    const media = mediaType.parse(resp.headers.get('Content-Type'));
                    return Promise.resolve(resp.text())
                        .then(response => {
                            if (media.subtype === 'json' || media.suffix === 'json') {
                                response = JSON.parse(response);
                            }
                            return Promise.resolve(dispatch({
                                    type: actionTypes.POSTFETCH_SUCCESS,
                                    payload: {
                                        url,
                                        method,
                                        response,
                                        token
                                    }
                                }))
                                .then(() => ({response}));
                        });
                })
                .catch(error => {
                    return Promise.resolve(dispatch({
                        type: actionTypes.POSTFETCH_FAIL,
                        payload: {
                            url,
                            method,
                            error,
                            token
                        }
                    }))
                    .then(() => {
                        if (throwOnError) {
                            throw error;
                        }
                        return {error};
                    });
                })
                .finally(() => dispatch({
                    type: actionTypes.POSTFETCH,
                    payload: {
                        url,
                        method,
                        token
                    }
                }));
        });
};