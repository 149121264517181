import React from 'react';

import Immutable from 'immutable';

import RCSelect, {Option as RCOption} from 'rc-select';

import {getPopupContainer} from '../functions';

import classNames from 'classnames';

import styles from './Select.module.scss';

export default class Select extends React.Component {
    static defaultProps = {
        items: [],
        asField: false,
        optionLabelProp: 'label',
        allowClear: false,
        placeholder: 'choose...',
        addEmpty: false,
        firstDefault: false,
        showArrow: true,
        tokenSeparators: [';', ' ', ','],
        forceKeys: false,
        dropdownAlign: {
            points: ['tl', 'bl'],
            offset: [0, 0]
        }
    }
    getItemsList = (props = this.props, force = false, native = false) => {
        const {items, addEmpty, placeholder, forceKeys} = props;
        if (!this.itemsList || !this.itemsValues || force) {
            this.itemsValues = [];
            this.itemsList = [];
            let itemsList = items && items.map ? items : [];
            if (addEmpty || addEmpty === '') {
                itemsList = itemsList.unshift({
                    value: addEmpty !== true ? addEmpty : '',
                    label: placeholder
                });
            }
            itemsList && itemsList.forEach && itemsList.forEach((i, k) => {
                const itm = Immutable.fromJS(typeof i === 'object' ? i : {title: i, value: forceKeys ? k : i});
                const value = '' + (Immutable.List.isList(itm) ? itm.get(0) : itm.get('value'));
                const titleItem = Immutable.List.isList(itm) ? itm.get(1, value) : itm.get('title', itm.get('label'));
                const disabled = !!((Immutable.List.isList(itm) ? itm.get(2) : itm.get('disabled')));
                const title = typeof titleItem === 'object' ? titleItem.get('name') : titleItem;
                if (this.itemsValues.indexOf(value) < 0) {
                    this.itemsValues.push(value);
                    this.itemsList.push({
                        value,
                        title: '' + title,
                        titleItem,
                        disabled
                    });
                }
            });
        }

        return {
            itemsValues: this.itemsValues,
            itemsList: native && props.placeholder ? [...this.itemsList, {disabled: true, title: props.placeholder}] : this.itemsList
        };
    }
    renderOptions = (native = false) => {
        const OptionComponent = native ? 'option' : RCOption;
        const {itemsList} = this.getItemsList(this.props, false, native);

        return itemsList && itemsList.map ? itemsList.map(({value, title, disabled, titleItem}, k) => {
            let children = title;
            if (!native) {
                if (titleItem && typeof titleItem === 'object') {
                    const subTitle = titleItem.get('name');
                    const subDesc = titleItem.get('description');
                    children = <span key="option-title" className={classNames(styles['option-title'], subDesc && styles['with-desc'])}>
                        <span key="option-title-name" className={styles['option-title-name']}>{subTitle}</span>
                        {subDesc ? <span key="option-title-description" className={styles['option-title-description']}>{titleItem.get('description')}</span> : null}
                    </span>;
                }
            }
            const optionProps = {
                className: this.props.optionClassName,
                key: `select-item-${k}`,
                disabled,
                value,
                title,
                children
            };
            if (!native) {
                optionProps.label = title;
            }
            return <OptionComponent {...optionProps}/>;
        }, this) : null;
    }
    render = () => {
        const {input} = this.props;
        const inputProps = {
            ...this.props,
            ...(input || {})
        };

        delete inputProps.asField;
        delete inputProps.component;
        delete inputProps.props;
        delete inputProps.asArray;
        delete inputProps.noDefer;
        delete inputProps.noOverwriteName;
        delete inputProps.noRef;
        delete inputProps.validate;
        delete inputProps.label;
        delete inputProps.dragState;
        delete inputProps.meta;
        delete inputProps.addEmpty;
        delete inputProps.firstDefault;
        delete inputProps.handlingEvents;
        delete inputProps.formIsPristine;
        delete inputProps.formIsValid;
        delete inputProps.formIsDisabled;
        delete inputProps.formIsSubmitting;
        delete inputProps.optionClassName;
        delete inputProps.flat;

        inputProps.className = 'select';
        inputProps.prefixCls = 'select';

        inputProps.animation = 'slide-up';
        
        inputProps.notFoundContent = this.props.combobox ? '' : this.props.notFoundContent;
        inputProps.showArrow = this.props.showArrow && !this.props.combobox;

        inputProps.allowClear = this.props.allowClear || this.props.multiple || this.props.tags;

        inputProps.getPopupContainer = getPopupContainer(this);

        inputProps.filterOption = (value, option) => {
            const search = `${value}`.toLowerCase();
            const optionValue = option && option.props && typeof option.props.value !== 'undefined' ? `${option.props.value}`.toLowerCase() : '';
            const optionTitle = option && option.props && typeof option.props.title !== 'undefined' ? `${option.props.title}`.toLowerCase() : '';

            return optionValue.indexOf(search) > -1 || optionTitle.indexOf(search) > -1;
        };
        return  <div className={classNames(this.props.className, styles['select-container'], this.props.flat && styles['flat'])}>
            <select name={inputProps.name} multiple={inputProps.multiple || inputProps.tags} value={inputProps.value} disabled={inputProps.disabled} onChange={inputProps.onChange} children={this.renderOptions(true)}  tabIndex="-1" readOnly={inputProps.readOnly}/>
            <RCSelect {...inputProps} children={this.renderOptions()}/>
        </div>;
    };
}