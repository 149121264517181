import React from 'react';

import classNames from 'classnames';

import Tooltip from './Tooltip';

import styles from './Input.module.scss';

export default class Input extends React.Component {
    static defaultProps = {
        type: 'text',
        name: 'input',
        value: '',
        persistWrap: true
    }
    render = () => {
        const {input, meta, persistWrap} = this.props;
        const {touched, error} = meta ? meta : {};
        const inputProps = {
            ...this.props,
            ...(input || {})
        };

        delete inputProps.input;
        delete inputProps.asField;
        delete inputProps.component;
        delete inputProps.props;
        delete inputProps.asArray;
        delete inputProps.noDefer;
        delete inputProps.noOverwriteName;
        delete inputProps.noRef;
        delete inputProps.validate;
        delete inputProps.asComponent;
        delete inputProps.label;
        delete inputProps.dragState;
        delete inputProps.bold;
        delete inputProps.flat;
        delete inputProps.meta;
        delete inputProps.active;
        delete inputProps.handlingEvents;
        delete inputProps.onOptionDelete;
        delete inputProps.persistWrap;
        delete inputProps.formIsPristine;
        delete inputProps.formIsValid;
        delete inputProps.formIsDisabled;
        delete inputProps.formIsSubmitting;
        delete inputProps.inheritName;
        if (this.props.flat) {
            inputProps.size = inputProps.value.length;
        }
        const submitting = meta && meta.submitting;
        inputProps.disabled = inputProps.disabled || (meta && meta.submitting);


        let InputComponent = 'input';
        if (inputProps.asEditable || inputProps.contentEditable) {
            InputComponent = 'div';
            inputProps.contentEditable = true;
            inputProps.suppressContentEditableWarning = true;
            inputProps.children = inputProps.value;
        }

        inputProps.className = classNames(inputProps.className, styles['input'], {[styles['as-editable']]: inputProps.asEditable || inputProps.contentEditable});

        delete inputProps.asEditable;
        delete inputProps.withRef;
        inputProps.ref = 'node';

        const inputField = <InputComponent {...inputProps}/>;
        return persistWrap || (touched && error) ? <Tooltip className={styles['error-tooltip']} visible={!!(touched && error && !submitting)} error={error} noWrap>{inputField}</Tooltip> : inputField;
    };
}