import {debounce} from 'lodash';
import { batchActions } from 'redux-batched-actions';
import {createLogger, defaults as loggerDefaults} from 'redux-logger';
import Immutable from 'immutable';

/**
 * @autothor https://gist.github.com/brentmulligan
 * Create a redux middleware function that debounces specified
 * action types and processes them together with batchActions.
 *
 * @param {array} actionTypes - An array of actions types to debounce
 * @param {number} [wait=0] - Time to wait before executing action batch
 * @returns {function} - middleware function
 */
export const Debouncer = (actionTypes, wait = 0) => {
    const actionQueue = {};
    const debouncedDispatch = {};
    const DEBOUNCE_ACTION = {};

    const dispatch = (store, key) => {
        store.dispatch(batchActions(actionQueue[key]));
        actionQueue[key] = [];
    };

    const debounceAction = (store, action) => {
        actionQueue[action.type].push(action);
        return debouncedDispatch[action.type](store, action.type);
    };

    actionTypes.forEach((type) => {
        DEBOUNCE_ACTION[type] = true;
        actionQueue[type] = [];
        debouncedDispatch[type] = debounce(dispatch, wait);
    });

    return (store) => next => action => (
        DEBOUNCE_ACTION[action.type] ? debounceAction(store, action) : next(action)
    );
};

export const ExtLogger = createLogger({
    collapsed: true,
    colors: {
        ...loggerDefaults.colors,
        title: null
    },
//    predicate: predicateLogger,
    titleFormatter: (action, time, took) => `action ${action.type} @${time} (in ${took.toFixed(2)}ms)`,
    stateTransformer: obj => Immutable.isImmutable(obj) ? obj.toJS() : obj
});