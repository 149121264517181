/* global Promise */

import React from 'react';
import {Field, SubmissionError} from 'redux-form/immutable';

import classNames from 'classnames';

import {formConnector} from '../connector';

import {prepareHistoryDateBounds, getHistoryDateBounds} from '../functions';

import Datepicker from './Datepicker';
import Select from './Select';
import Button from './Button';

export class HistoryForm extends React.Component {
    static incidentTypes = [
        {title: 'All', value: ''},
        {title: 'Queue Slowdown', value: 'warning'},
        {title: 'Platform Outage', value: 'critical'},
        {title: 'System Maintenance', value: 'deploy'}
    ];
    submitHandle = e => {
        const {handleSubmit} = this.props;

        const hndl = handleSubmit(values => {
            let ret;
            if (this.props.onSubmit) {
                ret = Promise.resolve(this.props.onSubmit(values)).catch(e => {
                    const formErrors = {_error: e.message};
                    throw new SubmissionError(formErrors);
                });
            }
            return ret;
        });

        return hndl(e);
    }
    resetHandle = e => {
        if (typeof this.props.reset === 'function') {
            this.props.reset(e);
        }
        if (typeof this.props.onReset === 'function') {
            this.props.onReset(e);
        }
    }

    getDisabledDate = field => current => {
        const {startDate, finishDate} = prepareHistoryDateBounds(
            this.props.values && this.props.values.start,
            this.props.values && this.props.values.finish,
            true
        );
        const bound = getHistoryDateBounds('min');
        const min = field === 'finish' ? startDate : finishDate.clone().subtract(bound[0], bound[1]);
        const max = field === 'finish' ? undefined : finishDate;

        return current && current.isBetween && !current.isBetween(
            min,
            max,
            'days',
            '[]');
    }

    render = () => {
        const {styles, submitting} = this.props;

        const disabled = this.props.disabled || submitting;

        const items = HistoryForm.incidentTypes;

        return <form className={this.props.className} name="history" onSubmit={this.submitHandle} onReset={this.resetHandle}>
            <div className={styles.row}>
                <div className={classNames(styles.field, styles['field-type'])}>
                    <label htmlFor="type">Type:</label>
                    <Field className={styles.input} component={Select} name="type" props={{items, optionClassName: styles['history-form-select-option']}} onChange={this.props.onTypeChange}/>
                </div>
                <div className={classNames(styles.field, styles['field-start'])}>
                    <label htmlFor="start">From:</label>
                    <Field className={styles.input} component={Datepicker} name="start" props={{disabledDate: this.getDisabledDate('start'), calendarClassName: styles['history-form-calendar']}}/>
                </div>
                <div className={classNames(styles.field, styles['field-finish'])}>
                    <label htmlFor="finish">To:</label>
                    <Field className={styles.input} component={Datepicker} name="finish" props={{disabledDate: this.getDisabledDate('finish'), calendarClassName: styles['history-form-calendar']}}/>
                </div>
                <div className={classNames(styles.field, styles['field-apply'])}>
                    <Button bold className={styles.button} disabled={disabled} type="submit">Apply</Button>
                    <Button className={styles.button} disabled={disabled} type="reset" >Reset</Button>
                </div>
            </div>
        </form>;
    };
}

export default formConnector(
    {form: 'filter'},
    ['start', 'finish']
)(HistoryForm);