/* global Promise */

import React from 'react';
import { Field, reduxForm, SubmissionError} from 'redux-form/immutable';

import Input from './Input';
import Button from './Button';

export class SubscribeForm extends React.Component {
    submitHandle = e => {
        const {handleSubmit} = this.props;

        const hndl = handleSubmit(values => {
            let ret;
            if (this.props.onSubmit) {
                ret = Promise.resolve(this.props.onSubmit(values))
                    .then(() => {
                        setTimeout(() => this.setState({subscribeDisabled: false}), 5000);
                        return this.setState({subscribed: true, subscribeDisabled: true});
                    })
                    .catch(e => {
                        const formErrors = {_error: e.message};
                        throw new SubmissionError(formErrors);
                    });
            }
            return ret;
        });

        return hndl(e);
    }
    render = () => {
        const {error, submitting} = this.props;
        const styles = this.props.styles || {};

        const disabled = this.props.disabled || submitting;
        const subscribed = this.state && this.state.subscribed;
        const subscribeDisabled = this.state && this.state.subscribeDisabled;

        return <form className={this.props.className} onSubmit={this.submitHandle}>
            <Field className={styles.input} required component={Input} type="email" name="email" placeholder="Enter your email address" props={{readOnly: !!subscribeDisabled, autoFocus: this.props.autoFocus}}/>
            <Button bold className={styles.button} disabled={disabled || subscribeDisabled} type="submit">{subscribeDisabled ? 'Subscribed!' : 'Subscribe'}</Button>
            {!submitting && subscribed ? <strong className={styles.subscribed}>Successfully subscribed</strong> : null}
            {!submitting && error ? <strong className={styles.error}>{error}</strong> : null}
        </form>;
    };
}

export default reduxForm({
  form: 'subscribe'
})(SubscribeForm);