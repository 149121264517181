import React from 'react';

import {actionConnector} from '../connector'

import Status from './Status';
import History from './History';

import Icon from './Icon';

import {parseURLString} from '../functions';

import {ReactComponent as Logo} from '../svg/logo.svg';
import {ReactComponent as IconUser} from '../svg/icons/user.svg';
import {ReactComponent as IconDesktop} from '../svg/icons/desktop.svg';
import {ReactComponent as IconCode} from '../svg/icons/code.svg';
import {ReactComponent as IconCheckCircle} from '../svg/icons/check-circle.svg';
import {ReactComponent as IconHome} from '../svg/icons/home.svg';

import styles from './Container.module.scss';

export class Container extends React.Component {
    componentWillMount = () => {
        let location = {};
        try {
            location = (window && window.location && window.location.href && parseURLString(window.location.href)) || {};
        } catch (e) {
            console.error(e);
        }

        const {init} = this.props.actions || {};

        if (typeof init === 'function') {
            init(location);
        }
    };
    render = () => {
        return  [
            <header key="header" className={styles['status-header']}>
                <a href="/"><Logo className={styles['edc-logo']}/></a>
                <h1 className={styles['edc-logo-text']}>System Status</h1>
                <nav className={styles['header-nav']}>
                    <ul className={styles['list']}>
                        <li><a className={styles['nav-menu-item-element']} href="https://manage.encoding.com/ui"><Icon className={styles.ico} ico={IconUser}/>UI</a></li>
                        <li><a className={styles['nav-menu-item-element']} href="https://www.encoding.com/"><Icon className={styles.ico} ico={IconHome}/>Home</a></li>
                        <li><a className={styles['nav-menu-item-element']} href="https://api.encoding.com/"><Icon className={styles.ico} ico={IconCode}/>API</a></li>
                        <li><a className={styles['nav-menu-item-element']} href="https://uptime.encoding.com/"><Icon className={styles.ico} ico={IconCheckCircle}/>Uptime</a></li>
                        <li><a className={styles['nav-menu-item-element']} href="https://help.encoding.com/"><Icon className={styles.ico} ico={IconDesktop}/>Online Help</a></li>
                    </ul>
                </nav>
            </header>,
            <main key="main" className={styles['status-container']}>
                <Status />
                <History />
            </main>
        ];
    };
}

export default actionConnector(Container);
