import React from 'react';

import classNames from 'classnames';

import {toMoment, toNeutralTZ} from '../functions';

import Calendar from './Calendar';
import Tooltip from './Tooltip';

import styles from './HistoryCalendar.module.scss';

export default class HistoryCalendar extends React.Component {
    getDate = () => {
        const date = toNeutralTZ(toMoment(this.props.date));
        date.date(1);

        return date;
    }

    getIncidentWarnClass = incident => {
        let warnType = 'warning';
        if (!incident.has('critical') || incident.get('critical') || incident.get('status_tag') === 'error') {
            warnType = 'critical';
        }
        if (incident.get('status_tag') === 'deploy') {
            warnType = 'deploy';
        }
        return warnType;
    }

    checkIncidentActive = incident => {
        const filterIncident = this.props.filter && this.props.filter.get && this.props.filter.get('incident');
        const incID = incident && incident.get && parseInt(incident.get('id'));
        return filterIncident
            && incID
            && filterIncident.indexOf
            && ~filterIncident.indexOf(incID);
    }

    renderIncidentTooltip = (dateTitle, incidents = []) => {
        return <div className={styles['incident-tooltip-description']}>
            <h4 className={styles['incident-date']}>{dateTitle}</h4>
            {incidents && incidents.length ? <ul className={styles['incident-list']}>
                {incidents.map(inc => <li key={inc.get('id')} className={classNames(styles['incident-list-item'], styles[`incident-${inc.get('status') === 'Resolved' ? 'resolved' : 'open'}`], styles[`incident-class-${this.getIncidentWarnClass(inc)}`], this.checkIncidentActive(inc) ? styles['incident-active'] : false)}>
                    <h5 className={styles['incident-item-head']}>
                        <sub className={styles['incident-status']}>{inc.get('status')}</sub>
                        {inc.get('published').format('HH:mm:ss')}
                        &nbsp;&mdash;&nbsp;
                        {inc.get('name')}
                    </h5>
                    <p className={styles['incident-list-desc']}>{inc.get('description')}</p>
                    {inc.get('updates') && inc.get('updates').count() ? inc.get('updates').map(upd => <p key={upd.get('id')} className={styles['incident-list-update']}>
                        {toMoment(upd.get('published')).format('HH:mm:ss')}
                        &nbsp;&mdash;&nbsp;
                        {upd.get('description') || 'updated'}
                    </p>) : null}
                    {inc.has('resolved') ? <p className={styles['incident-list-resolved']}>
                        {inc.get('resolved').format('HH:mm:ss')}
                        &nbsp;&mdash;&nbsp;
                        {inc.get('resolvenote') || 'Resolved'}
                    </p> : null}
                </li>)}
            </ul> : null}
        </div>;
    };

    handleVisibleChange = (dateKey, visible) => {
        const activeDates = (this.state && this.state.activeDates) || {};
        activeDates[dateKey] = visible;
        this.setState({activeDates});
    }

    handleTipClose = incIDs => {
        let ret;
        if (typeof this.props.onTooltipClose === 'function') {
            ret = this.props.onTooltipClose(incIDs);
        }

        return ret;
    }

    dateRender = current => {
        const curDate = toNeutralTZ(toMoment(current));
        let dateElem = null;
        if (curDate.isSame(this.getDate(), 'month')) {
            const start = this.props.filter && this.props.filter.get && this.props.filter.get('start');
            const finish = this.props.filter && this.props.filter.get && this.props.filter.get('finish');
            if (curDate.isBetween(start, finish, 'days', '[]')) {
                const dateKey = curDate.format('MM-DD');
                const dateTitle = curDate.format('MMMM Do, YYYY');
                const cellClasses = ['calendar-date'];

                const tipProps = {
                    onVisibleChange: this.handleVisibleChange.bind(this, dateKey)
                };
                let incBG = null;

                const incidents = [];

                const activeIncidents = [];

                if (this.props.incidents && this.props.incidents[dateKey] && this.props.incidents[dateKey].forEach) {
                    const filterType = this.props.filter && this.props.filter.get && this.props.filter.get('type');
                    this.props.incidents[dateKey].forEach(inc => {
                        if (!filterType || this.getIncidentWarnClass(inc) === filterType) {
                            incidents.push(inc);
                            if (this.checkIncidentActive(inc)) {
                                activeIncidents.push(parseInt(inc.get('id')));
                            }
                        }
                    });
                }

                tipProps.placement = "right";

                if (incidents.length) {
                    tipProps.placement = "bottomLeft";
                    tipProps.overlayClassName = styles['incident-tooltip']
                    tipProps.solid = true;
                    cellClasses.push(styles['with-incidents']);
                    if (incidents.forEach) {
                        const bgStripes = [];
                        incidents.forEach(inc => {
                            const warnType = this.getIncidentWarnClass(inc);
                            if (!inc.has('critical') || inc.get('critical')) {
                                cellClasses.push(styles['with-critical-incidents']);
                            }
                            if (inc.get('status_tag') === 'deploy') {
                                cellClasses.push(styles['with-deploy']);
                            }
                            const bgStripeStyle = {
                                flexGrow: inc.get('downtime') + 1
                            };
                            bgStripes.unshift(<div key={inc.get('id')} style={bgStripeStyle} className={classNames(styles['bg-stripe'], styles[`bg-stripe-${warnType}`])}/>);
                        });
                        incBG = <div className={styles['bg-grad']}>{bgStripes}</div>;
                    }
                }
                if (activeIncidents.length) {
                    tipProps.visible = true;
                    tipProps.trigger = [];
                    tipProps.closable = true;
                    tipProps.destroyTooltipOnHide = true;
                    tipProps.onClose = this.handleTipClose.bind(this, activeIncidents);
                }

                if (activeIncidents.length || (this.state && this.state.activeDates && this.state.activeDates[dateKey])) {
                    cellClasses.push(styles['active']);
                }

                tipProps.overlay = this.renderIncidentTooltip(dateTitle, incidents);

                dateElem = <Tooltip {...tipProps}><div className={classNames(cellClasses)}><div className={styles['date-value']}>{curDate.date()}</div>{incBG}</div></Tooltip>;
            } else {
                dateElem = <div className={classNames('calendar-date', styles['out-of-range'])}><div className={styles['date-value']}>{curDate.date()}</div></div>;
            }
        }

        return dateElem;
    }
    render = () => {
        this.persistTooltipDate = null;
        const date = this.getDate();
        const calendarProps = {
            value: date,
            dateRender: this.dateRender
        };

        const uptime = this.props.uptime >= 0 || this.props.uptime <= 100 ? this.props.uptime : 100;
        
        return <div className={classNames(this.props.className, styles['history-calendar'])}>
            <h4 className={styles['history-calendar-header']}>
                <span>{date.format('MMMM YYYY')}</span>
                <div className={styles['history-calendar-uptime']}>{uptime.toFixed(2)}%</div>
            </h4>
            <div className={styles['history-calendar-table']}>
                <Calendar {...calendarProps}/>
            </div>
        </div>;
    }
}