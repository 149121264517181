/*global module, require */
import {applyMiddleware, compose, createStore} from 'redux';

import Immutable from 'immutable';

import thunk from 'redux-thunk';
import {ExtLogger, Debouncer} from './enhancers';

import {actionTypes as reduxFormActions} from 'redux-form/immutable';

import rootReducer from './reducer';

let store;

export default function configureStore(initialState = {}) {
    if (!store) {
        const middlewares = [];
        middlewares.push(thunk);
        if (reduxFormActions) {
            const excludeActions = [
                reduxFormActions.DESTROY,
                reduxFormActions.CHANGE,
                reduxFormActions.BLUR,
                reduxFormActions.FOCUS,
                reduxFormActions.INITIALIZE,
                reduxFormActions.RESET,
                reduxFormActions.ARRAY_INSERT,
                reduxFormActions.ARRAY_MOVE,
                reduxFormActions.ARRAY_POP,
                reduxFormActions.ARRAY_PUSH,
                reduxFormActions.ARRAY_REMOVE,
                reduxFormActions.ARRAY_REMOVE_ALL,
                reduxFormActions.ARRAY_SHIFT,
                reduxFormActions.ARRAY_SPLICE,
                reduxFormActions.ARRAY_SWAP,
                reduxFormActions.ARRAY_UNSHIFT,
                reduxFormActions.UPDATE_SYNC_ERRORS,
                reduxFormActions.prefix
            ];
            const reduxFormActionList = Object.values(reduxFormActions);/*
                .concat([TOOLTIP_ACTION_REGISTER, TOOLTIP_ACTION_UNREGISTER, TOOLTIP_ACTION_SHOW, TOOLTIP_ACTION_HIDE, TOOLTIP_ACTION_PERSIST]);*/
            middlewares.push(Debouncer(reduxFormActionList.filter(action => excludeActions.indexOf(action) < 0), 100)); //eslint-disable-line new-cap
        }


        let composeEnhancers = compose;
        if (process.env.NODE_ENV !== 'production') {
            middlewares.push(ExtLogger);
        }
        const enhList = composeEnhancers(applyMiddleware(...middlewares));
        store = createStore(
            rootReducer,
            Immutable.fromJS(initialState, (key, value) => Immutable.Iterable.isIndexed(value) ? value.toList() : value.toOrderedMap()),
            enhList
        );
    }
    return store;
}
if (module.hot && module.hot.onUpdate) {
    module.hot.onUpdate(() => {
        const nextReducer = require('./reducer');
        if (nextReducer) {
            store.replaceReducer(nextReducer.default ? nextReducer.default : nextReducer);
        }
        return true;
    });
}