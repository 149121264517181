import React from 'react';

import configureStore from './store';
import {Provider} from 'react-redux';

import Container from './components/Container';

import './reset.css';
import './App.scss';

export default class App extends React.Component {
    componentWillMount = () => {
        this.store = configureStore();
    }

    render = () => <Provider store={this.store}>
        <Container />
    </Provider>;
}
