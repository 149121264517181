import React from 'react';
import url from 'url';

import {createBrowserHistory} from 'history';

import {historyConnector} from '../connector'

import {toMoment, toNeutralTZ, isMoment, prepareHistoryDateBounds, toArray, parseURLString, queryStringify} from '../functions';

import Loader from './Loader'

import HistoryForm from './HistoryForm';

import HistoryCalendar from './HistoryCalendar';

import classNames from 'classnames';

import styles from './History.module.scss';

export class History extends React.Component {
    componentWillMount = () => this.checkHistory()
    componentWillReceiveProps = props => this.checkHistory(props)

    componentWillUnmount = () => {
        if (this.historyUnlisten) {
            this.historyUnlisten();
        }
    }

    checkHistoryListener = () => {
        if (!this.historyHandler) {
            this.historyHandler = createBrowserHistory();
        }
        if (!this.historyUnlisten) {
            this.historyUnlisten = this.historyHandler.listen(this.historyListener);
        }
    }

    checkHistory = (props = this.props) => {
        this.checkHistoryListener();
        if (!props.systemHistory) {
            this.loadHistory(props);
        }
    }

    historyListener = (location, action) => {
        if (action === 'POP') {
            let params;
            if (location.state && location.state.params) {
                params = JSON.parse(location.state.params);
            } else {
                try {
                    params = parseURLString(location.path + location.search, true).query;
                } catch (e) {
                    console.error(e);
                }
            }
            if (params) {
                this.loadHistory(undefined, params, true);
            }
        }
    }

    updateLocation = (newLocation = '', prevLocation = '', force = false, push = true, params = {}) => {
        this.checkHistoryListener();
        let ret;
        if (!prevLocation) {
            prevLocation = this.historyHandler && url.format(this.historyHandler.location);
        }
        if (newLocation && (newLocation !== prevLocation || force)) {
            const histHandler = push ? 'push' : 'replace';
            this.historyHandler
                && this.historyHandler[histHandler]
                && this.historyHandler[histHandler](
                    newLocation,
                    {newLocation, prevLocation, force, params: JSON.stringify(params)}
                );
        }

        return ret;
    };

    loadHistory = (props = this.props, params = {}, ignoreFilter = false, updateLocation = false) => {
        const filterParams = {
            start: null,
            finish: null,
            type: null,
            from: null,
            limit: 1000,
            ...(params || {})
        }

        const {fetchHistory} = props.actions || {};

        if (typeof fetchHistory === 'function') {
            this.setState({loading: true});
            fetchHistory(filterParams, ignoreFilter)
                .then(() => {
                    if (updateLocation) {
                        this.checkLocationUpdate(filterParams);
                    }
                })
                .finally(() => {
                    this.setState({loading: false});
                });
        }
    }

    refreshHandle = () => this.loadHistory();

    handleTipClose = incIDs => {
        let ret;
        const filterIncidents = this.props.statusFilter && this.props.statusFilter.get('incident');
        const hideIDs = toArray(incIDs);
        if (filterIncidents && filterIncidents.filter && hideIDs.length) {
            ret = this.setHistoryIncident(
                undefined,
                filterIncidents.filter(i => hideIDs.indexOf(i) < 0)
            );
        }
        return ret;
    }

    renderLog = () => {
        const log = this.props.systemHistory && this.props.systemHistory.get('log');

        const {startDate, finishDate} = prepareHistoryDateBounds(
            this.props.statusFilter && this.props.statusFilter.get('start'),
            this.props.statusFilter && this.props.statusFilter.get('finish')
        );

        const monthCount = finishDate.date(1).diff(startDate.date(1), 'months') + 1;

        const monthList = {};

        const mKeyFormat = 'YYYY-MM';

        for (let i = 0; i < monthCount; i++) {
            const monthDate = finishDate.clone().subtract(i, 'months').date(1);
            const totalTime = monthDate.daysInMonth() * 24 * 3600;

            monthList[monthDate.format(mKeyFormat)] = {
                date: monthDate,
                downtime: 0,
                totalTime,
                incidents: {}
            };
        }

        if (log && log.forEach) {
            log.forEach(incident => {
                const published = toMoment(incident.get('published'));
                const incMonthKey = published.format(mKeyFormat);

                if (monthList[incMonthKey]) {
                    const incKey = published.format('MM-DD');
                    const resolved = toMoment(incident.get('resolved'));
                    const downtime = resolved.diff(published, 'seconds');
                    if (!incident.has('critical') || incident.get('critical')) {
                        monthList[incMonthKey].downtime += downtime;
                    }

                    let newIncident = incident.set('published', published).set('downtime', downtime);
                    if (newIncident.has('resolved')) {
                        newIncident = newIncident.set('resolved', resolved)
                    }
                    if (!monthList[incMonthKey].incidents[incKey]) {
                        monthList[incMonthKey].incidents[incKey] = [];
                    }
                    monthList[incMonthKey].incidents[incKey].push(newIncident);
                }


            });
        }

        return <div className={styles.log}>
            {Object.keys(monthList).map(mk => {
                const {date, downtime, totalTime, incidents} = monthList[mk];
                const uptime = (1 - downtime / totalTime) * 100;
                return <HistoryCalendar  className={styles['log-item']} key={mk} date={date} uptime={uptime} incidents={incidents} filter={this.props.statusFilter} onTooltipClose={this.handleTipClose}/>;
            })}
            <ul className={styles.legend}>
                {HistoryForm.incidentTypes.map(type => {
                    const code = type.value || 'ok';
                    const title = type.value ? type.title : 'No issues';
                    return <li key={code} className={classNames(styles['legend-code'], styles[`legend-code-${code}`])}>
                        <span className={styles['legend-icon']}>{code}</span>
                        &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
                        {title}
                    </li>;
                })}
            </ul>
        </div>;
    }

    prepareLocation = (values = null) => {
        this.checkHistoryListener();
        let newLocation = '';
        if (this.historyHandler && this.historyHandler.location) {
            const valKeys = ['start', 'finish', 'type', 'incident'];
            try {
                const locationVars = {...this.historyHandler.location};
                const queryVars = parseURLString(locationVars.path + locationVars.search).query;
                delete locationVars.search;
                valKeys.forEach(k => {
                    if (values && (typeof values[k] !== 'undefined' || (values.get && values.has(k)))) {
                        let newVal = typeof values[k] !== 'undefined' ? values[k] : values.get(k);
                        if (isMoment(newVal)) {
                            newVal = newVal.format('YYYY-MM-DD');
                        }
                        queryVars[k] = newVal && newVal.toJS ? newVal.toJS() : newVal;
                    } else {
                        delete queryVars[k];
                    }
                });
                
                for (var l in queryVars) {
                    if (queryVars[l] === null || queryVars[l] === undefined) {
                        delete queryVars[l];
                    }
                }
                delete locationVars.query;
                locationVars.search = '?' + queryStringify(queryVars);
                newLocation = url.format(locationVars);
            } catch (e) {
                console.error(e);
            }
        }
        return newLocation;
    }

    checkLocationUpdate = (values = null) => {
        const newLocation = this.prepareLocation(values);
        return this.updateLocation(
            newLocation,
            null, //prevLocation
            false, //force
            true, //push
            values // stateParams
        );
    }

    setHistoryType = (props = this.props, type) => {
        let ret;
        const {setHistoryType} = props.actions || {};

        if (typeof setHistoryType === 'function') {
            ret = setHistoryType(type);
        }

        return ret;
    }

    setHistoryIncident = (props = this.props, incident) => {
        let ret;
        const {setHistoryIncident} = props.actions || {};

        if (typeof setHistoryIncident === 'function') {
            ret = setHistoryIncident(incident);
            this.checkLocationUpdate({incident});
        }

        return ret;
    }

    submitHandle = values => {
        const params = {
            start: toNeutralTZ(toMoment(values && values.get('start'))),
            finish: toNeutralTZ(toMoment(values && values.get('finish'))),
            type: values && values.get('type')
        };
        return this.loadHistory(undefined, params, true, true);
    }

    resetHandle = () => {
        this.setHistoryType(undefined, '');
        return this.loadHistory(undefined, {}, true, true);
    }

    typeChangeHandle = (val) => {
        return this.setHistoryType(undefined, val);
    }

    render = () => {
        const loading = this.state && this.state.loading;
        const filterInitial = {
            start: this.props.statusFilter && this.props.statusFilter.get('start'),
            finish: this.props.statusFilter && this.props.statusFilter.get('finish'),
            type: this.props.statusFilter && this.props.statusFilter.get('type')
        };

        return  <section className={styles.history}>
            {loading || !this.props.systemHistory ? <Loader className={styles.loader}/> : null}
            <h2 className={styles.header}>System Event History</h2>
            <HistoryForm styles={styles} className={styles['history-form']} enableReinitialize initialValues={filterInitial} onReset={this.resetHandle} onSubmit={this.submitHandle} onTypeChange={this.typeChangeHandle}/>
            {this.props.systemHistory ? this.renderLog() : null}
        </section>
    }
}

export default historyConnector(History);
