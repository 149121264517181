import React from 'react';

import classNames from 'classnames';

import styles from './Icon.module.css'

export default class Icon extends React.Component {
    render = () => {
        const props = {...this.props};
        delete props.ico;
        props.className = classNames(props.className, styles.icon);
        return <span {...props}><this.props.ico /></span>;
    }
}