import {combineReducers} from 'redux-immutable';
import {enableBatching} from 'redux-batched-actions';

import appReducer from './app.reducer';
import {reducer as formReducer} from 'redux-form/immutable';


export default enableBatching(combineReducers({
    app: appReducer,
    form: formReducer
}));