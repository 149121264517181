const prefix = 'edc-status';

export default {
    INIT: `${prefix}@INIT`,
    FETCH_STATUS: `${prefix}@FETCH_STATUS`,
    FETCH_STATUS_ERROR: `${prefix}@FETCH_STATUS_ERROR`,
    FETCH_HISTORY: `${prefix}@FETCH_HISTORY`,
    FETCH_HISTORY_ERROR: `${prefix}@FETCH_HISTORY_ERROR`,
    SET_HISTORY_TYPE: `${prefix}@ACTION_SET_HISTORY_TYPE`,
    SET_HISTORY_INCIDENT: `${prefix}@ACTION_SET_HISTORY_INCIDENT`,
    PREFETCH: `${prefix}@ACTION_PREFETCH`,
    POSTFETCH_SUCCESS: `${prefix}@ACTION_POSTFETCH_SUCCESS`,
    POSTFETCH_FAIL: `${prefix}@ACTION_POSTFETCH_FAIL`,
    POSTFETCH: `${prefix}@ACTION_POSTFETCH`
};