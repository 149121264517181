import 'whatwg-fetch';
import 'es6-promise/auto';
import 'promise.prototype.finally/auto';
//import 'es6-symbol/implement';
import objectAssign from 'object.assign';
import objectValues from 'object.values';
import arrayFrom from 'array.from';
import arrayIncludes from 'array-includes';

if (typeof Number !== 'undefined' && typeof Number.isFinite === 'undefined') {
    Number.isFinite = value => typeof value === 'number' && isFinite(value);
}
if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.startsWith = function(search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
}
if (objectValues && !Object.values) {
    objectValues.shim();
}
if (objectAssign && !Object.assign) {
    objectAssign.shim();
}
if (arrayFrom && !Array.from) {
    arrayFrom.shim();
}
if (arrayIncludes && !Array.includes) {
    arrayIncludes.shim();
}