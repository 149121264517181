import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form/immutable';

import * as actions from './actions';

export const connector = (Component) => connect(
    state => ({app: state.get('app')}),
    dispatch => ({actions: bindActionCreators(actions, dispatch)}),
    null,
    {
        forwarRef: true,
        pure: true
    }
)(Component);

export const actionConnector = (Component) => connect(
    () => ({}),
    dispatch => ({actions: bindActionCreators(actions, dispatch)}),
    null,
    {
        forwarRef: true,
        pure: true
    }
)(Component);

export const statusConnector = (Component) => connect(
    state => ({systemStatus: state.getIn(['app', 'systemStatus'])}),
    dispatch => ({actions: bindActionCreators(actions, dispatch)}),
    null,
    {
        forwarRef: true,
        pure: true
    }
)(Component);

export const historyConnector = (Component) => connect(
    state => ({
        systemHistory: state.getIn(['app', 'systemHistory']),
        statusFilter: state.getIn(['app', 'statusFilter'])
    }),
    dispatch => ({actions: bindActionCreators(actions, dispatch)}),
    null,
    {
        forwarRef: true,
        pure: true
    }
)(Component);

export const formConnector = (props, fieldValues = []) => Component => connect(
    state => {
        const values = {};
        const selector = formValueSelector(props.form);
        fieldValues.forEach(field => {
            values[field] = selector(state, field);
        });
        return {values};
    },
    undefined,
    null,
    {
        forwarRef: true,
        pure: true,
        areStatesEqual: (prev, next) => prev && next
            && prev.get && next.get
            && prev.get('form') && next.get('form')
            && prev.get('form').equals(next.get('form'))
    }
)(reduxForm(props)(Component));

export default connector;