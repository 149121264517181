import React from 'react';

import classNames from 'classnames';

import './Tooltip.css'


import Button from './Button'
import Icon from './Icon';
import {ReactComponent as CloseIcon} from '../svg/icons/close.svg';

import {getPopupContainer} from '../functions';

import RCTooltip from 'rc-tooltip';

export default class Tooltip extends React.Component {
    closeHandle = () => {
        this.setState({closed: true});
        setTimeout(() => this.setState({closed: false}), 10);
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }
    ref = node => {
        this.tooltipNode = node;
    }
    renderOverlay = () => {
        return <div className={classNames('tooltip-cont-wrapper', this.props.closable ? 'with-controls' : false)}>
            <ul className="tooltip-cont-controls">
                {this.props.closable ? <li><Button className="button" icoPosition="only" onClick={this.closeHandle}><Icon className={classNames('ico', Button.styles.ico)} ico={CloseIcon}/></Button></li> : null}
            </ul>
            <div className="tooltip-cont-text">{this.props.overlay}</div>
        </div>
    }
    render = () => {
        const overlayClassNames = {
            'tooltip': true,
            'tooltip-error': !!this.props.error,
            'tooltip-notify': !!this.props.notify,
            'tooltip-solid': !!this.props.solid,
            'tooltip-as-dialog': !!this.props.asDialog,
            'tooltip-fat-arrow': !!this.props.fatArrow
        };
        const tooltipProps = {
            prefixCls: 'tooltip',
            placement: 'right',
            ...this.props,
            overlayClassName: classNames(this.props.className, overlayClassNames),
            getPopupContainer: getPopupContainer(this),
            overlay: this.renderOverlay(),
            ref: this.ref
        };

        if (this.state && this.state.closed) {
            tooltipProps.visible = false;
        }

        return <RCTooltip {...tooltipProps}/>;
    }
}